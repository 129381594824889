import React from "react";
import ReactGa from "react-ga";

// reactstrap components
import { Row, Container, Media, Col } from "reactstrap";

function Footer() {
  const linkedIn = () => {
    ReactGa.event({
      category: "Footer",
      action: "Clicked LinkedIn Footer",
    });
  };

  const instagram = () => {
    ReactGa.event({
      category: "Footer",
      action: "Clicked Instagram Footer",
    });
  };

  const twitter = () => {
    ReactGa.event({
      category: "Footer",
      action: "Clicked Twitter Footer",
    });
  };

  const email = () => {
    ReactGa.event({
      category: "Footer",
      action: "Clicked Email Footer",
    });
  };

  return (
    <Container id="contact">
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
          backgroundColor: "#e5e5e5",
          borderRadius: 30,
          paddingTop: 50,
          paddingBottom: 50,
        }}
      >
        <Col
          sm="4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              marginLeft: 26,
            }}
          >
            <a
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
                color: "black",
              }}
            >
              Contact Us
            </a>
            <a
              href={`mailto:contactrecspot@gmail.com`}
              style={{ fontFamily: "Poppins", color: "black" }}
              onClick={email}
            >
              contactrecspot@gmail.com
            </a>
            <a style={{ fontFamily: "Poppins", color: "black" }}>
              (647)-501-0177
            </a>
          </div>
        </Col>
        <Col
          sm="4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            paddingBottom: 25,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <a
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
                color: "black",
                marginRight: 40,
              }}
            >
              Follow Us
            </a>
            <a
              style={{ color: "black", fontSize: 14, fontFamily: "Poppins" }}
              target="_blank"
              href="https://www.linkedin.com/company/recspotlinkedin/"
              onClick={linkedIn}
            >
              Linkedin
            </a>
            <a
              style={{ color: "black", fontSize: 14, fontFamily: "Poppins" }}
              target="_blank"
              href="https://www.instagram.com/recspot_/"
              onClick={instagram}
            >
              Instagram
            </a>
            <a
              style={{ color: "black", fontSize: 14, fontFamily: "Poppins" }}
              target="_blank"
              href="https://twitter.com/RecSpot"
              onClick={twitter}
            >
              Twitter
            </a>
          </div>
        </Col>
        <Col
          sm="4"
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <a
              style={{
                fontFamily: "Poppins",
                fontSize: 20,
                fontWeight: "bold",
                color: "black",
                marginRight: 80,
              }}
            >
              Legal
            </a>
            <a style={{ color: "black", fontSize: 14, fontFamily: "Poppins" }}>
              Terms & Conditions
            </a>
            <a style={{ color: "black", fontSize: 14, fontFamily: "Poppins" }}>
              Privacy Policy
            </a>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Footer;
