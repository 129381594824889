import React from "react";

// reactstrap components
import { Container, Row, Media, Col } from "reactstrap";
import { manager, money, budget, asian } from "../assets/images";
require("./styles.css");

function Managers() {
  return (
    <div id="managers">
      <div style={{ marginTop: 100 }} className="text-center">
        <Container>
          <h2
            style={{
              fontWeight: "bold",
              fontSize: 44,
              fontFamily: "Poppins",
              marginBottom: 15,
            }}
          >
            {" "}
            Why Your Business Needs To Use RecSpot Today{" "}
          </h2>
        </Container>
      </div>

      <Container>
        <Row>
          <Col
            lg="6"
            style={{ paddingLeft: 50, paddingRight: 50, marginTop: 90 }}
          >
            <h1 style={{ fontFamily: "Poppins" }}>
              Booking and Payment <br /> Integration{" "}
            </h1>
            <h4 class="our-story-text">
              Up to 60% of online bookings are made outside regular business
              hours. It's time to give your customers what they want with 24/7
              booking and payment availability. Save time, save money, limit
              contact, move online.
            </h4>
          </Col>
          <Col lg="6" style={{}}>
            <Media
              src={manager}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                float: "left",
              }}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
            <h1 style={{ fontFamily: "Poppins" }}>Care for your Customers</h1>
            <h4 class="our-story-text">
              People are apprehensive to enter recreational facilities, however
              RecSpot provides you with technological resources to keep your
              customers safe, and increase their confidence in you
            </h4>
          </Col>
          <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
            <Media
              src={money}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                float: "left",
              }}
            />
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
            <h1 style={{ fontFamily: "Poppins" }}>Work within your Budget</h1>
            <h4 class="our-story-text">
              Local recreation is what keeps us going, and we’re dedicated to
              ensuring small businesses survive and thrive during these
              difficult times. Give us a shout, and we’ll help you get started
              no matter where you’re at!
            </h4>
          </Col>
          <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
            <Media
              src={budget}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                float: "left",
              }}
            />
          </Col>
        </Row>
      </Container>

      <Container>
        <Row style={{ display: "flex", flexDirection: "row-reverse" }}>
          <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
            <h1 style={{ fontFamily: "Poppins" }}>Pack your Schedule</h1>
            <h4 class="our-story-text">
              Keep your days organized with our live & customizable online
              scheduling. Help your customers find and book your activities
              easily and in advance. Discover new customers through our
              platform. Let business boom!
            </h4>
          </Col>
          <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
            <Media
              src={asian}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "contain",
                float: "left",
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Managers;
