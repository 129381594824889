import React from "react";
import {Button, Container, Media} from "reactstrap";
import ReactGa from "react-ga";
import {sitting} from "../../assets/images";
import {Link} from "react-scroll";

require("../../views/styles.css");
// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  const clickHandler = () => {
    ReactGa.event({
      category: "Button",
      action: "Click Get Started Button",
    });
  };

  React.useEffect(() => {
    if (window.innerWidth < 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <div data-parallax={true} ref={pageHeader}>
        {/* <div className="filter" /> */}
        <Container style={{display: "flex", marginTop: 100, marginBottom: 50}}>
          <div
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              display: "flex",
            }}
          >
            <div
              // className="motto"
              style={{
                flex: 1,
                flexDirection: "column",
                justifyContent: "center",
                display: "flex",
              }}
            >
              <h30 style={{color: "#474747", fontSize: 30, fontFamily: "Poppins", fontWeight: 'bold'}}>
              Revolutionizing Recreation
              </h30>

              {<h3 style={{ color: "#707070", fontFamily: "Poppins"}}>Sign up to use our App!</h3>}

              <Button
                color="info"
                href="#pablo"
                style={{
                  marginTop: 60,
                  borderRadius: 300,
                  fontFamily: "Poppins", 
                  fontWeight: "bold", 
                  fontSize: 18,
                  width: "250px",
                }}
                onClick={clickHandler}
              >
                <Link
                  to="contact"
                  spy={true}
                  activeClass="active"
                  smooth={true}
                  offset={-70}
                  duration={500}
                  style = {{
                    fontFamily: "Poppins", 
                    

                  }}
                >
                  Get Started
                  </Link>
                
              </Button>
            </div>
            <div id="girl-sitting-container">
              <Media
                id="girl-sitting"
                src={sitting}
                style={{width: "100%", height: "100%", objectFit: "contain"}}
              />
            </div>
          </div>
        </Container>
      </div>
    </>
  );
}

export default LandingPageHeader;
