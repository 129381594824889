import React from "react";

// reactstrap components
import { Container, Row, Media, Col } from "reactstrap";
import { lit } from "../assets/images";
require("./styles.css");

function OurStory() {
  return (
    <Container id="story">
      <Row>
        <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
          <Media
            src={lit}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
              float: "left",
            }}
          />
        </Col>
        <Col lg="6" style={{ paddingLeft: 50, paddingRight: 50 }}>
          <h1
            style={{ fontFamily: "Poppins", fontWeight: "bold", fontSize: 45 }}
          >
            What We Do
          </h1>
          <h4 class="our-story-text">
            COVID-19 has changed all of our lives drastically, and we’re tired
            of being indoors. It’s time to have some fun, but as our
            recreational facilities reopen, we need to make sure we’re staying
            safe.
          </h4>
          <h4 class="our-story-text">
            RecSpot helps small businesses move their point of sale online
            through our online marketplace, and provides businesses with simple
            contact tracing methods to keep customers safe. Simultaneously, our
            platform gives customers a place to find, filter through, and book
            their favourite local activities at the touch of their fingertips.
          </h4>
        </Col>
      </Row>
    </Container>
  );
}

export default OurStory;
