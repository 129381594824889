import React from "react";
import ReactGa from "react-ga";
import {useEffect} from "react";

function Analytics() {
  useEffect(() => {
    ReactGa.initialize("UA-170786592-1");
    ReactGa.pageview("/");
  }, []);

  return <h2> </h2>;
}

export default Analytics;
