import React, {useState} from "react";

// reactstrap components
import {
  Container,
  Button,
  Input,
  Label,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
} from "reactstrap";
import MailchimpSubscribe from "react-mailchimp-subscribe";

require("./styles.css");
const url =
  "//gmail.us10.list-manage.com/subscribe/post?u=01223108f7d163196199c900a&id=6b7fb78e1a";

function Newsletter() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [value, setValue] = useState("Just Looking To Have Fun");

  return (
    <Container style={{display: "flex"}}>
      <div className="row">
        <div className="col-sm-4 col-sm-push-8">
          <h1 style={{fontFamily: "Poppins", fontWeight: "bold"}}>
            Be the first to try it!
          </h1>
          <h4 class="our-story-text">
            Sign up to be the first to know when RecSpot is in your
            neighbourhood
          </h4>
        </div>

        <div
          className="col-sm-8 col-sm-pull-4"
          style={{marginTop: 45, marginBottom: 45}}
        >
          <MailchimpSubscribe
            url={url}
            render={({subscribe, status, message}) => {
              const submit = () => {
                const name = `${firstName} ${lastName}`;
                console.log('name', name)
                console.log(email)
                subscribe({
                  EMAIL: email,
                  FNAME: firstName,
                  LNAME: lastName,
                  MMERGE3: value,
                });
              };

              return (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    borderStyle: "solid",
                    borderWidth: 1,
                    borderRadius: 25,
                    borderColor: "#D3D3D3",
                    flexDirection: "column",
                  }}
                >
                  <div>
                    {status === "sending" && (
                      <div
                        style={{color: "blue", marginLeft: 25, marginTop: 10}}
                      >
                        Sending...
                      </div>
                    )}
                    {status === "error" && (
                      <div
                        style={{
                          color: "red",
                          display: "flex",
                          marginLeft: 25,
                          marginTop: 10,
                        }}
                        dangerouslySetInnerHTML={{__html: message}}
                      />
                    )}
                    {status === "success" && (
                      <div
                        style={{
                          color: "green",
                          display: "flex",
                          marginLeft: 25,
                          marginTop: 10,
                        }}
                        dangerouslySetInnerHTML={{__html: message}}
                      />
                    )}
                    <Label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: 20,
                        margin: 25,
                        marginBottom: 5,
                      }}
                    >
                      Email Address
                    </Label>
                    <Input
                      style={{
                        borderWidth: 1,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: 20,
                        display: "flex",
                        width: "90%",
                        marginLeft: 25,
                        marginRight: 25,
                      }}
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                    />
                    <Label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: 20,
                        margin: 25,
                        marginBottom: 5,
                      }}
                    >
                      First Name
                    </Label>
                    <Input
                      style={{
                        borderWidth: 1,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: 20,
                        display: "flex",
                        width: "90%",
                        marginLeft: 25,
                        marginRight: 25,
                      }}
                      onChange={(e) => setFirstName(e.target.value)}
                      type="firstName"
                    />
                    <Label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: 20,
                        margin: 25,
                        marginBottom: 5,
                      }}
                    >
                      Last Name
                    </Label>
                    <Input
                      style={{
                        borderWidth: 1,
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: 20,
                        display: "flex",
                        width: "90%",
                        marginLeft: 25,
                        marginRight: 25,
                      }}
                      onChange={(e) => setLastName(e.target.value)}
                      type="lastName"
                    />
                    <Label
                      style={{
                        fontFamily: "Poppins",
                        fontWeight: "bold",
                        fontSize: 20,
                        margin: 25,
                        marginBottom: 5,
                      }}
                    >
                      I Am ...
                    </Label>
                    <div>
                      <UncontrolledDropdown style={{marginLeft: 25}}>
                        <DropdownToggle
                          style={{
                            backgroundColor: "#918BFF",
                            borderColor: "#918BFF",
                            color: "white",
                          }}
                          caret
                        >
                          {value}
                        </DropdownToggle>
                        <DropdownMenu>
                          <DropdownItem
                            style={{backgroundColor: "#918BFF", color: "white"}}
                            onClick={(e) => {
                              setValue(e.target.value);
                            }}
                            value="a Business Owner/Manager"
                          >
                            a Business Owner/Manager
                          </DropdownItem>
                          <DropdownItem divider />
                          <DropdownItem
                            style={{backgroundColor: "#2DD6D1", color: "white"}}
                            onClick={(e) => {
                              setValue(e.target.value);
                            }}
                            value="Just Looking To Have Fun"
                          >
                            Just Looking To Have Fun
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignSelf: "center",
                      }}
                    >
                      <Button
                        style={{
                          marginTop: 25,
                          marginBottom: 25,
                          width: 250,
                          backgroundColor: "#2DD6D1",
                          borderColor: "#2DD6D1",
                          color: "white",
                        }}
                        onClick={() => {
                          console.log("on press");
                          submit();
                        }}
                      >
                        Submit
                      </Button>
                    </div>
                  </div>
                </div>
              );
            }}
          />
        </div>
      </div>
    </Container>
  );
}

export default Newsletter;
