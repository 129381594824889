import React from "react";

// reactstrap components
import { Container, Row, Media, Col } from "reactstrap";

import { creditCard, discount, person, Mask } from "../assets/images";

function FeatureCard() {
  return (
    <Container style={{ marginBottom: 50, marginTop: 100 }}>
      <Row
        style={{
          flexDirection: "row",
          justifyContent: "space-between",
          display: "flex",
        }}
      >
        <Col
          md="4"
          style={{
            paddingTop: 0,
            paddingLeft: 50,
            paddingRight: 50,
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Media
              src={person}
              style={{ width: "100px", height: "100px", marginBottom: 20 }}
            />
            <h3
              style={{
                fontWeight: "bold",
                fontSize: 25,
                fontFamily: "Poppins",
                color: "black",
                marginBottom: 10,
                textAlign: "left",
              }}
            >
              {" "}
              Building Communities{" "}
            </h3>
            <p
              style={{
                textAlign: "left",
                fontFamily: "Poppins",
                fontSize: 20,
              }}
            >
              Connecting recreational businesses to customers in a way never
              seen before.
            </p>
          </div>
        </Col>
        <Col
          md="4"
          style={{
            paddingTop: 0,
            paddingLeft: 45,
            paddingRight: 45,
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Media
              src={creditCard}
              style={{ width: "100px", height: "100px", marginBottom: 20 }}
            />
            <h3
              style={{
                fontWeight: "bold",
                fontSize: 24,
                fontFamily: "Poppins",
                color: "black",
                marginBottom: 10,
                textAlign: "left",
              }}
            >
              {" "}
              Bookings & Payments{" "}
            </h3>
            <p
              style={{
                textAlign: "left",
                fontFamily: "Poppins",
                fontSize: 20,
              }}
            >
              Convenient for customers, easy to implement for businesses,
              affordable for everyone.
            </p>
          </div>
        </Col>
        <Col
          md="4"
          style={{
            paddingTop: 0,
            paddingLeft: 50,
            paddingRight: 45,
            paddingBottom: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Media
              src={Mask}
              style={{ width: "100px", height: "100px", marginBottom: 20 }}
            />
            <h3
              style={{
                fontWeight: "bold",
                fontSize: 25,
                fontFamily: "Poppins",
                color: "black",
                marginBottom: 10,
                textAlign: "left",
              }}
            >
              {" "}
              Safety First{" "}
            </h3>
            <p
              style={{
                textAlign: "left",
                fontFamily: "Poppins",
                fontSize: 20,
              }}
            >
              From contactless payments, to limiting capacity, to contact
              tracing, RecSpot is everything we need to keep businesses open,
              and customers safe
            </p>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default FeatureCard;
