import React from "react";
import ReactGa from "react-ga";

import { Thenura, Neeraj, Karen, Abi } from "../assets/images";

// reactstrap components
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  Media,
} from "reactstrap";

require("./styles.css");

function OurTeam() {
  const thenura = () => {
    ReactGa.event({
      category: "Profiles",
      action: "Clicked Thenura's Profile",
    });
  };

  const neeraj = () => {
    ReactGa.event({
      category: "Profiles",
      action: "Clicked Neeraj's Profile",
    });
  };

  const karen = () => {
    ReactGa.event({
      category: "Profiles",
      action: "Clicked Karen's Profile",
    });
  };

  const abi = () => {
    ReactGa.event({
      category: "Profiles",
      action: "Clicked Abi's Profile",
    });
  };

  return (
    <div id="team" style={{ marginTop: 50 }} className="text-center">
      <Container>
        <h2 style={{ marginBottom: 15, fontWeight: "bold", fontSize: 45, fontFamily: "Poppins"}}>
          {" "}
          Our Team{" "}
        </h2>
        <Row>
          <Col md="4">
            <Card className="card-profile card-plain">
              <CardBody>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/thenura/"
                  onClick={thenura}
                >
                  <div className="author">
                    <Media
                      src={Thenura}
                      style={{
                        width: "95%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: 200,
                      }}
                    />
                    <CardTitle style={{ fontWeight: "bold", fontFamily: "Poppins"}} tag="h3">
                      Thenura Jayasinghe
                    </CardTitle>
                    <h3
                      style={{ fontWeight: "bold", fontFamily: "Poppins"}}
                      className="card-category"
                    >
                      CTO, Co-Founder
                    </h3>
                  </div>
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-profile card-plain">
              <CardBody>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/neeraj-varma-6437b6170/"
                  onClick={neeraj}
                >
                  <div className="author" style={{ paddingLeft: 20 }}>
                    <Media
                      src={Neeraj}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: 200,
                      }}
                    />
                    <CardTitle style={{ fontWeight: "bold", fontFamily: "Poppins" }} tag="h3">
                      Neeraj Varma
                    </CardTitle>
                    <h3
                      style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                      className="card-category"
                    >
                      CEO, Co-Founder
                    </h3>
                  </div>
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-profile card-plain">
              <CardBody>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/karenkikichan/"
                  onClick={karen}
                >
                  <div className="author" style={{ paddingLeft: 20 }}>
                    <Media
                      src={Karen}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: 200,
                      }}
                    />
                    <CardTitle style={{ fontWeight: "bold", fontFamily: "Poppins"  }} tag="h3">
                      Karen Chan
                    </CardTitle>
                    <h3
                      style={{ fontWeight: "bold", fontFamily: "Poppins"}}
                      className="card-category"
                    >
                      Head of Design
                    </h3>
                  </div>
                </a>
              </CardBody>
            </Card>
          </Col>
          <Col md="4">
            <Card className="card-profile card-plain">
              <CardBody>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/in/abisheik-suboshan-7b7710168/"
                  onClick={abi}
                >
                  <div className="author">
                    <Media
                      src={Abi}
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "contain",
                        borderRadius: 200,
                      }}
                    />
                    <CardTitle style={{ fontWeight: "bold", fontFamily: "Poppins" }} tag="h3">
                      Abisheik Suboshan
                    </CardTitle>
                    <h3
                      style={{ fontWeight: "bold", fontFamily: "Poppins" }}
                      className="card-category"
                    >
                      Software Developer
                    </h3>
                  </div>
                </a>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default OurTeam;
