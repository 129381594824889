import React, {useEffect, useRef, useState} from "react";
import ReactGa, { modalview } from "react-ga";
// import {Link} from "react-router-dom";
// nodejs library that concatenates strings
import classnames from "classnames";
import { colors } from "../../styles";
import { recspotLogo } from "../../assets/images";
import { Link } from "react-scroll";

// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  // NavLink,
  Nav,
  Container,
  Media,
} from "reactstrap";
import { NavHashLink as NavLink } from "react-router-hash-link";


function ExamplesNavbar() {

  
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [navbarCollapse, setNavbarCollapse] = React.useState(false);

  const toggleNavbarCollapse = (name) => {

    ReactGa.event({
      category: "Navbar",
      action: `Clicked ${name} Navbar`,
    });


    
    setNavbarCollapse(!navbarCollapse);
    document.documentElement.classList.toggle("nav-open");
  };

  

  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop >= 10 ||
        document.body.scrollTop >= 10
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 10 ||
        document.body.scrollTop < 10
      ) {
        setNavbarColor("navbar-transparent");
      }
    };

    window.addEventListener("scroll", updateNavbarColor);

    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });


  const node = useRef();

  useEffect(() => {
    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);

  const handleClick = e => {
    if (node.current.contains(e.target)) {
      onClick = {toggleNavbarCollapse}
    }
      onClick = {toggleNavbarCollapse}
    } 
  
  
    

  return (
    <Navbar
      className={classnames("fixed-top", navbarColor)}
      // color-on-scroll="0"
      expand="lg"
      style={{ paddingTop: 10 }}
    >
      <Container>
        <div
          className="navbar-translate"
          style={{
            display: "flex",
            flexDirection: "row",
            
            // justifyContent: "space-between",
          }}
       
        >
          <NavbarBrand
            data-placement="top"
            style={{ padding: 0, marginRight: 50, width: "250px" }}
          >
            <Link
              to="main"
              spy={true}
              activeClass="active"
              smooth={true}
              offset={-70}
              duration={500}
            >
              <Media
                src={recspotLogo}
                style={{
                  display: "flex",
                  width: "75%",
                  height: "75%",
                  objectFit: "contain",
                  marginBottom: 10,
                }}
              />
            </Link>
          </NavbarBrand>
           <div
          ref = {node}
           > 
          <button
        
            aria-expanded={navbarCollapse}
            className={classnames("navbar-toggler navbar-toggler", {
              toggled: navbarCollapse,
            })}
            onClick={toggleNavbarCollapse}
          >
            <span className="navbar-toggler-bar bar1" />
            <span className="navbar-toggler-bar bar2" />
            <span className="navbar-toggler-bar bar3" />
          </button>
          
          </div>
        </div>
       
        
        <Collapse
          className="justify-content-end"
          navbar
          isOpen={navbarCollapse}
        >
          <Nav navbar>
            <NavItem>
              <Link
                style={{
                  marginRight: 25,
                  color: colors.black,
                  fontFamily: "Poppins",
                  fontSize: 22,
                  textTransform: "capitalize",
                }}
                to="story"
                spy={true}
                activeClass="active"
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => {toggleNavbarCollapse("about")}}
              >
                About
              </Link>
            </NavItem>
            <NavItem>
              <Link
                style={{
                  marginRight: 25,
                  color: colors.black,
                  fontFamily: "Poppins",
              
                  fontSize: 22,
                  textTransform: "capitalize",
                }}
                to="managers"
                spy={true}
                activeClass="active"
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => {toggleNavbarCollapse("managers")}}
              >
                For Managers
              </Link>
            </NavItem>
            <NavItem>
              <Link
                style={{
                  marginRight: 25,
                  color: colors.black,
                  fontFamily: "Poppins",
                  fontSize: 22,
                  textTransform: "capitalize",
                }}
                to="team"
                spy={true}
                activeClass="active"
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => {toggleNavbarCollapse("team")}}
              >
                Team
              </Link>
            </NavItem>
            <NavItem>
              <Link
                style={{
                  marginRight: 25,
                  color: colors.black,
                  fontFamily: "Poppins",
                  
                  fontSize: 22,
                  textTransform: "capitalize",
                }}
                to="contact"
                spy={true}
                activeClass="active"
                smooth={true}
                offset={-70}
                duration={500}
                onClick={() => {toggleNavbarCollapse("contact us")}}
              >
                Contact
              </Link>
            </NavItem>
          </Nav>
        </Collapse>
        
      </Container>
    </Navbar>
  );
}

export default ExamplesNavbar;
