import React from "react";

import LandingPage from "./LandingPage.js";
import Footer from "../components/Footers/Footer.js";
import OurStory from './OurStory';
import Managers from './Managers';
import OurTeam from "./OurTeam";
import Newsletter from './Newsletter';
import Analytics from './Analytics';
import FeatureCard from "./FeatureCards.js";


function Index() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("index");
    return function cleanup() {
      document.body.classList.remove("index");
    };
  });
  return (
    <>
      <div className="main">
        <LandingPage />
        <OurStory />
        <FeatureCard />
        <Managers />
        <OurTeam />
        <Newsletter />
        <Footer />
        <Analytics/>
        
      </div>
    </>
  );
}

export default Index;
